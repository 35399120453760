<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Redirects</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Redirects"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  v-on="on"
                  @click="$refs.redirectDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Redirect</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>
      <v-data-table
        :headers="tableHeaders"
        :items="redirects"
        no-data-text="There are currently no redirects"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                @click="$refs.redirectDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <redirect-dialog ref="redirectDialog" />
  </div>
</template>

<script>
import RedirectDialog from "./components/RedirectDialog.vue";

export default {
  components: {
    RedirectDialog,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "SEO",
          disabled: true,
        },
        {
          text: "Redirection",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Source URL", value: "source_url" },
        { text: "Target URL", value: "target_url" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteRedirect: {
        dialog: false,
        redirect: {},
        loading: false,
      },
    };
  },

  computed: {
    redirects() {
      let redirects = this.$store.getters["seo/redirectionStore/allRedirects"];

      if (this.searchTerm !== "") {
        redirects = redirects.filter((c) => {
          const source_url = c.source_url.toLowerCase();
          const target_url = c.target_url.toLowerCase();

          return (
            source_url.includes(this.searchTerm.toLowerCase()) ||
            target_url.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return redirects;
    },
  },
};
</script>
