<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} Redirect
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="redirect-form">
          <v-text-field
            label="Source URL *"
            v-model="fields.source_url"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('source_url')"
            :error-messages="errors['source_url']"
          ></v-text-field>
          <v-text-field
            label="Target URL *"
            v-model="fields.target_url"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('target_url')"
            :error-messages="errors['target_url']"
          ></v-text-field>
          <v-switch
            label="Is Active?"
            v-model="fields.is_active"
            inset
            color="green"
            :error="errors.hasOwnProperty('is_active')"
            :error-messages="errors['is_active']"
          ></v-switch>
          <v-select
            label="Response Code *"
            v-model="fields.response_code"
            :items="response_codes"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('response_code')"
            :error-messages="errors['response_code']"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="redirect-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      redirect: {},
      fields: {
        source_url: null,
        target_url: null,
        is_active: true,
        response_code: 301,
      },
      response_codes: [301, 302, 404],
      errors: {},
    };
  },

  methods: {
    open(redirect = null) {
      if (redirect !== null) {
        this.redirect = redirect;
        this.isEditing = true;
        this.fields.source_url = redirect.source_url;
        this.fields.target_url = redirect.target_url;
        this.fields.is_active = redirect.is_active;
        this.fields.response_code = redirect.response_code;
      }

      this.dialog = true;
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.redirect = {};
      this.fields.source_url = null;
      this.fields.target_url = null;
      this.fields.is_active = true;
      this.fields.response_code = 301;
      this.errors = {};
      this.$refs.formWrapper.scrollTop = 0;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.redirectId = this.redirect.id;
      }

      this.$store
        .dispatch("seo/redirectionStore/saveRedirect", payload)
        .then(() => this.reset())
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },
  },
};
</script>
